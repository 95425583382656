import mainjson from '../config/main';
import axios from "axios";

export async function TokenValidator(returntologin) {
  //  console.log('returntologin 1',returntologin);
    let body = {
        agent:navigator.userAgent
    }

    if (localStorage) {
        body["token"] = localStorage.getItem('token');
    }
    
    console.log("mainjson",mainjson());

    let ret = false;
    
    await axios({
        method: "post",
        url: mainjson().validate_path,
        data: body,
      })
    .then((response) => {
        console.log(response.data);
        if (response.data.validtoken) {
            console.log('return true');
            ret = true;
        }
        else {
            console.log('return false');
            ret = false;
        }
    })
    .catch((error) => {
        console.log('return false');
        console.log("error "+error);
        ret = false;
    });

    if (!ret) {
        if (localStorage) {
            localStorage.clear();
        }
        if (returntologin == true) {
        //    console.log('returntologin',returntologin);
           // console.log("location",window.location);
           if (window.location.pathname != "" && window.location.pathname  != "/") {
            window.location = "/";
           }
        }
    }

    return ret;
}

export function LoggedIn() {
    if (typeof localStorage !== "undefined") {
        if (localStorage && localStorage.getItem('token')) {
            return true;
        }
        else {
            return false;
        }
    }
    return false;
}

export function Level() {
    if (typeof localStorage !== "undefined") {
        if (localStorage.getItem('level')) {
            return localStorage.getItem('level');
        }
        else {
            return false;
        }
    }
    return false;
}