export default function () {
        let paths = [{ match: "localhost", url: "http://localhost:3000/local/v1/cms",apiurl: "http://localhost:3000/local/v1/api"},{match:"ticketboxing.com",url:"https://z3bl7plpn9.execute-api.us-east-1.amazonaws.com/production/v1/cms",apiurl: "https://z3bl7plpn9.execute-api.us-east-1.amazonaws.com/production/v1/api"}];
        
       // paths = [{match:"localhost",url:"https://rts-function.desperados.com/api/RKCMS5565"},{match:"ravetosavestatic",url:"https://ravetosave.azurewebsites.net/api/RKCMS5565"}];
       // paths = [{match:"localhost",url:"https://ravetosavedev.azurewebsites.net/api/RKCMS5565"},{match:"ravetosavestatic",url:"https://ravetosave.azurewebsites.net/api/RKCMS5565"}];
       
        
        let currentpath = "";
        let currentapipath = "";
        for (var i = 0; i < paths.length; i++) {
                if (typeof window !== 'undefined') {
                        if (location.href.indexOf(paths[i].match) != -1) {
                                currentpath = paths[i].url;
                                currentapipath = paths[i].apiurl;
                        }
                }
        }

        let path = currentpath;

        return {
                path:path,
                apipath:currentapipath,
                start_page: "/events",
                login_path: path + "/login",
                validate_path: path + "/validate",
                generate_path: path + "/generateCodes",
                list_path: path + "/list",
                record_path: path + "/record",
                export_path: path + "/export",
                user_video_path: path + "/videos/",
                languages:"nl,en",
                defaultlanguage:"nl"
        }
}