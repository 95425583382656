import React,{useRef,useEffect}  from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import axios from "axios";
import {TokenValidator,LoggedIn} from "../utils/LoginFunctions";

export const Button = styled.button`
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    text-transform: none;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 0px solid transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #613ab0;
    font-size: 1.09375rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    border-radius: 60px;
    padding: .75rem 1.5rem;
    cursor: pointer;
`;

export const SmallButton1 = styled(Button)`
font-size: 0.9rem;
    padding: 0rem 0.7rem;

`;

export const Error = styled.div`
    font-family: Poppins,sans-serif;
    line-height: 1.5;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-size: 80%;
    font-weight: 400;
    color: #e46a76;
`


export const BaseFormField = styled.input`
    outline: 0;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #212529;
    min-height: 38px;
    display: initial;
`

export const BaseTextArea = styled.textarea`
    outline: 0;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #212529;
    min-height: 38px;
    display: initial;
`

export const BaseSelect = styled.select`

outline: 0;
margin: 0;
font-family: inherit;
text-transform: none;
position: relative;
flex: 0 0 100%;
max-width: 100%;
display: inline-block;
width: 100%;
height: calc(1.5em + .75rem + 2px);
padding: .375rem 1.75rem .375rem .75rem;
font-size: .875rem;
font-weight: 400;
line-height: 1.5;
color: #4f5467;
vertical-align: middle;
border: 1px solid #e9ecef;
border-radius: .25rem;
transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
background: url(../../../assets/images/custom-select.png) right .75rem center no-repeat;
appearance: none;
`

export const FormSubmit = styled.button`
box-sizing: border-box;
outline: 0;
margin: 0;
font-family: inherit;
overflow: visible;
text-transform: none;
display: inline-block;
font-weight: 400;
text-align: center;
vertical-align: middle;
user-select: none;
border: 1px solid transparent;
padding: .375rem .75rem;
font-size: .875rem;
line-height: 1.5;
border-radius: .25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
color: #fff;
background-color: #00c292;
border-color: #00c292;
cursor: pointer
`

export const FormCancel = styled(FormSubmit)`
    background-color: #343a40;
    border-color: #343a40;
`
