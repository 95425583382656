import React,{useState,useEffect,createContext}  from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Menu from "./global/Menu";
import mainjson from '../config/main.js';
import { media } from "../utils";
import { Helmet } from "react-helmet";
import {TokenValidator,LoggedIn} from "../utils/LoginFunctions";
export const LanguageContext = createContext({});

const breakpoints = ["768px", "1440px"];

breakpoints.l = breakpoints[0];
breakpoints.xl = breakpoints[1];

const theme = {
  colors: {
    background: "#000000",
    primary: "#EB6408",
    white: "#FFFFFF",
  },
  breakpoints,
  mediaQueries: {
    l: `@media (min-width: ${breakpoints[0]})`,
    xl: `@media (min-width: ${breakpoints[1]})`,
  },
};

const FlexContainer = styled.div`
position:relative;
height:100vh;
width:100vw;
display:flex;
`


const Left = styled.div`
background-color:red;
height:100vh;
flex-direction: row;
overflow:hidden;
width:220px;
flex: 0 0 auto;
`

const Right = styled.div`
height:100%;
width:100%;
overflow:hidden;
`


const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Regular'), url('/fonts/Poppins-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Italic'), url('/fonts/Poppins-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Thin'), url('/fonts/Poppins-Thin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Thin Italic'), url('/fonts/Poppins-ThinItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraLight'), url('/fonts/Poppins-ExtraLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraLight Italic'), url('/fonts/Poppins-ExtraLightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light'), url('/fonts/Poppins-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light Italic'), url('/fonts/Poppins-LightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Medium'), url('/fonts/Poppins-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Medium Italic'), url('/fonts/Poppins-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins SemiBold'), url('/fonts/Poppins-SemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins SemiBold Italic'), url('/fonts/Poppins-SemiBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  src: local('Poppins Bold'), url('/fonts/Poppins-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold Italic'), url('/fonts/Poppins-BoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraBold'), url('/fonts/Poppins-ExtraBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraBold Italic'), url('/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Black';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Black'), url('/fonts/Poppins-Black.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Black Italic'), url('/fonts/Poppins-BlackItalic.woff') format('woff');
  }

html,
  body {
    padding: 0;
    margin: 0;
    width:100%;
    font-family:arial;
    height:100%;
    overscroll-behavior: none;
    font-family: Poppins,sans-serif;
  }

  html {
    box-sizing: border-box;

    
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #eeeeee;
  min-height: 100vh;
  width:100vw;

`;

export default function Layout({ children }) {
  const [loggedin, setLoggedin] = useState(false);
  const [language, setLanguage] = useState(mainjson().defaultlanguage);
  

  useEffect(() => {
    if (window && window.location.pathname  != "/") {
        TokenValidator(true);
    }
    console.log("loggedin ",LoggedIn());
    setLoggedin(LoggedIn());
  }, []);


  return (
    
    <LanguageContext.Provider value={{language,setLanguage}}>
    <ThemeProvider theme={theme}>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TicketBoxing CMS</title>
          <link rel="canonical" href="" />
          <meta
            name="description"
            content="TicketBoxing CMS"
          />
        </Helmet>
        <GlobalStyle />
        <FlexContainer>
        {loggedin && 
        <Left>
        <Menu />
        </Left>
        }
        <Right>
        {children}
        </Right>
        </FlexContainer>
      </Container>
    </ThemeProvider>
    </LanguageContext.Provider>
  );
}
